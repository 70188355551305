<template>
  <v-main id="projects-content" :class="$vuetify.theme.dark ? 'transparent-dark' : 'transparent-light'">
    <v-layout column align-center justify-center>
      <h1 class="font-weight-medium display-2 text-center">Projects</h1>
      <v-icon style="transform: scale(3,1)" class="mb-6">mdi-color-helper</v-icon>
      <v-container>
        <template>
          <v-layout row wrap justify-center class="my-12">
            <v-flex xs12 sm10 md11 lg12 xl8 class="my-4">
              <v-card class="mx-2" :class="$vuetify.theme.dark ? 'dark-transparent-grey' : 'light-transparent-grey'">
                <v-tabs v-model="tab" class="contentsecondary" background-color="transparent" grow show-arrows center-active>
                  <v-tab v-for="project in projects" :key="project.title">{{ project.title }}</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab" :class="$vuetify.theme.dark ? 'dark-transparent-grey' : 'light-transparent-grey'">
                  <v-tab-item v-for="project in projects" :key="project.title">
                    <v-container :class="$vuetify.theme.dark ? 'dark-transparent-grey' : 'light-transparent-grey'">
                      <v-row>
                        <v-col sm="12" md="6" offset-md="0" offset-lg="0">
                          <v-card flat color="rgb(255, 0, 0, 0.0)" class="pa-2 mx-auto">
                            <div align="center">
                              <v-img align="center" class="mb-6" :src="project.logo" width="20%"></v-img>
                            </div>
                            <p class="font-weight-regular title" style="white-space: pre-wrap;">{{project.description}}</p>
                            <a :href="project.link">{{ project.linkTitle }}</a>
                          </v-card>
                        </v-col>
                        <v-col sm="12" md="6" offset-md="0" offset-lg="0" class="image-carousel-container">
                          <v-card flat color="rgb(255, 0, 0, 0.0)" class="pa-2">
                            <v-carousel cycle height="100%"  interval="5000">
                              <v-carousel-item v-for="image in project.images" :key="image[0]">
                                <v-row class="fill-height" align="center" justify="center">
                                  <v-img eager :max-height="project.maxHeight" contain :src="getPic(image[0])" :lazy-src="getPic(image[1])" class="grey lighten-2 mt-4"></v-img>
                                </v-row>
                              </v-carousel-item>
                            </v-carousel>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-flex>
          </v-layout>
        </template>
      </v-container>
    </v-layout>
  </v-main>
</template>

<script>
export default {
  data () {
    return {
      tab: null,
      projects: {
        museEegLslMonitor: {
          title: 'Muse EEG LSL Tools',
          link: 'https://github.com/dan-pavlov/muse-eeg-lsl-python-tools',
          linkTitle: 'Muse EEG LSL Tools on Github',
          maxHeight: '100%',
          description:
            'Muse EEG LSL Tools is a collection of Python CLI tools that enable recording and displaying raw EEG data in real-time. These tools, in conjunction with BlueMuse (by kowalej) software, allow a currently unique ability to connect a python development environment to Muse EEG headband using any Bluetooth 4.0+ module over the LSL protocol. The most common alternative solution requires a specific Bluetooth hardware module to accomplish the same results. \n\nCurrently the tools include a script for recording and tagging EEG data for later analysis, as well as a monitor that displays real-time EEG waveforms at accurate electrical potentials, at maximum frequency supported by the device, with <50ms latency.',
          logo: require('../assets/images/muse.png'),
          images: {
            museImage1: ['muse.gif', 'muse.gif']
          }
        },
        cubos: {
          title: 'Cubos',
          link: 'https://play.google.com/store/apps/details?id=com.dpav.cubosrun',
          linkTitle: 'Cubos on Google Play Store',
          maxHeight: '310',
          description:
            'This is the second iteration of my game development endeavours in Unity game engine. Cubos is a geometric infinite runner with a challange based gameplay. \n\nThis is a feature complete game, every part of which, I created from the ground up: from level design, character and environment controllers, to sound engineering, graphics controls settings and online functionality.',
          logo: require('../assets/images/cubos_logo.png'),
          images: {
            cubosImage1: ['cubos_projects_1.jpg', 'cubos_projects_1_lowres.jpg'],
            cubosImage2: ['cubos_projects_2.jpg', 'cubos_projects_2_lowres.jpg'],
            cubosImage3: ['cubos_projects_3.jpg', 'cubos_projects_3_lowres.jpg']
          }
        },
        kaggleDataAnalysisNotebooks: {
          title: 'Kaggle Data Analysis Notebooks',
          link: 'https://www.kaggle.com/danpavlov/notebooks',
          linkTitle: 'Kaggle Notebooks Page',
          maxHeight: '100%',
          description:
            'Kaggle is the world\'s largest data science community with powerful tools and resources to help users achieve their data science goals. Participating in data science challenges and helping people understand and achieve your results is a crucial part of this comminuty. Visit the link below to see my contributions.',
          logo: require('../assets/images/kaggle_logo.png'),
          images: {
            kaggleImage1: ['kaggle_projects_1.png', 'kaggle_projects_1_lowres.jpg'],
            kaggleImage2: ['kaggle_projects_2.png', 'kaggle_projects_2_lowres.jpg']
          }
        },
        portfolioChatbot: {
          title: 'Personal Portfolio Chatbot',
          maxHeight: '100%',
          description:
            'DanBot 3000 was my personal project before the age modern generative AI became widely adopted, the aim of which was to demonstrate and practice my skills in natural language processing. It was based on a custom intent classification TensorFlow model, trained on manually generated dataset of prompts, and inferenced via a custom containerised deployment. The chatbot was accessible through UI interface written from the ground up. \n\nThe chatbot supported 67 different intents and over 100 unique responses. Supported topics included professional questions about myself, conversational topics with the chatbot, as well as executing actions in the web client. It has since been deprecated.',
          logo: require('../assets/images/mdi-robot-excited.png'),
          images: {
            museImage1: ['chatbot_project.gif', 'chatbot_project.gif']
          }
        },
        cubosWindowsPhone: {
          title: 'Cubos (Windows Phone)',
          link: 'https://www.microsoft.com/en-gb/p/cubos/9wzdncrdfkh6?activetab=pivot:overviewtab',
          linkTitle: 'Cubos on Microsoft Store',
          maxHeight: '310',
          description:
            'My first game development project was Cubos - a minimalistic 3D platformer, that was created for a 2 weeks long online competition, during which time, everything from controls and level design, to testing and music, was developed and composed. \n\nGame mechanics and the environment are intuitive yet challenging, as well as not lacking gameplay complexity. It has since been published on the Microsoft Store and has been downloaded over 12,000 times.',
          logo: require('../assets/images/cubos_wp_logo.jpg'),
          images: {
            cubosImage1: ['cubos_wp_projects_1.jpg', 'cubos_wp_projects_1_lowres.jpg'],
            cubosImage2: ['cubos_wp_projects_2.jpg', 'cubos_wp_projects_2_lowres.jpg']
          }
        }
      }
    }
  },
  methods: {
    getPic (fileName) {
      return require('../assets/images/' + fileName)
    }
  }
}
</script>
<style scoped>
.image-carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-application .light-transparent-grey {
  background-color: #ffffff3a !important;
}
.v-application .dark-transparent-grey {
  background-color: #25252530 !important;
}
#projects-content .v-window__container {
    height: 100% !important;
    min-height: 200px;
}
#projects-content .v-tabs-bar.v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-tabs-bar--show-arrows):not(.v-slide-group--has-affixes) .v-slide-group__prev {
  display: none;
}
#projects-content p {
  font-size: 1rem !important;
  line-height: 1.8rem !important;
}
.row {
  margin-right: -1px;
  margin-left: -1px;
}
</style>
